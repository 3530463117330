<template>
  <tbody v-if="visible">
    <tr>
      <td
        :colspan="cols"
        class="text-center py-16"
      >
        앱 선택이 필요합니다.
        <br>
        <br>
        <br>
        앱 선택하기
      </td>
    </tr>
  </tbody>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    cols: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
